export function MenuClose() {
  return (
    <>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 24L24 8" stroke="white" strokeWidth="2" strokeLinecap="round" />
        <path d="M24 24L8 8" stroke="white" strokeWidth="2" strokeLinecap="round" />
      </svg>
    </>
  );
}
